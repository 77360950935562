import { Container, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Footer from "../assets/components/Footer";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { app } from "../utils/server/firebase";

const VenFestival = () => {
  const db = getFirestore(app);
  const theme = useTheme();
  const [hoteles, setHoteles] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "hoteles"), (querySnapshot) => {
      const result = [];
      querySnapshot.forEach((doc) => {
        const hotel = doc.data();
        hotel.id = doc.id;
        result.push({ ...hotel });
      });

      setHoteles(result[0].hoteles);
    });
    return unsub;
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{ padding: "0 !important", margin: "0 !important" }}
    >
      <Seo title="Ven al festival" />
      <Navbar />

      <Grid
        container
        justifyContent="center"
        minHeight="100vh"
        paddingY={12}
        spacing={2}
      >
        <Grid item container maxWidth={1400}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                color="white"
                position="absolute"
                textAlign="center"
              >
                Ven al festival
              </Typography>
              <img
                src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/07/03/20230703153036-31df74fb-me.jpg"
                alt="festival del chocolate tabasco"
                style={{
                  width: "100%",
                  height: 228,
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={2}
          maxWidth={1400}
        >
          {hoteles.map((item) => (
            <Grid
              item
              container
              justifyContent="center"
              direction="column"
              maxWidth={345}
              component="a"
              href={item.urlvisite || null}
            >
              <Grid
                item
                style={{
                  borderTopRightRadius: 60,
                  overflow: "hidden",
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                <img
                  src={item.img}
                  alt="portada"
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: 345,
                    maxHeight: 190,
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ backgroundColor: theme.palette.primary.main }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="white"
                >
                  {item.nombre}
                </Typography>
              </Grid>
              <Grid
                item
                style={{ maxHeight: 110, backgroundColor: "#EDF2F2" }}
                textAlign="center"
                paddingY={2}
              >
                <img
                  src={item.logo}
                  alt="Logo hotel"
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: 180,
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                paddingBottom={5}
                paddingX={3}
                direction="column"
                style={{
                  borderBottomLeftRadius: 60,
                  overflow: "hidden",
                  backgroundColor: "#EDF2F2",
                }}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.primary.main}
                  >
                    {item.tarifa}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

export default VenFestival;
